(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon/assets/javascripts/coupon-single-row-mobile.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon/assets/javascripts/coupon-single-row-mobile.js');
"use strict";

const {
  useContext
} = React;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  CouponSingleRowBoard,
  CouponSingleRow
} = svs.components.tipsen.coupon;
const {
  CouponSingelPixChoices
} = svs.components.tipsen.coupon;
const CouponSingelRowsMobile = _ref => {
  let {
    couponId,
    events,
    outcomeLabels,
    selectedBoard
  } = _ref;
  const [productBranding] = useBranding();
  const {
    productId,
    drawNumber
  } = useContext(DrawContext);
  const mappedBoards = [];
  events.forEach(() => {
    mappedBoards.push(outcomeLabels);
  });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "coupon-single-rows"
  }, React.createElement("div", {
    className: "coupon-single-rows-event-column"
  }, events.map(drawEvent => React.createElement(CouponSingleRow, {
    branding: productBranding,
    drawNumber: drawNumber,
    event: drawEvent,
    isSingleRowForSmallDevice: true,
    key: drawEvent.eventNumber,
    productId: productId
  }))), React.createElement(CouponSingleRowBoard, {
    board: mappedBoards,
    boardIndex: selectedBoard,
    couponId: couponId,
    isSingleRowForSmallDevice: true,
    outcomeLabels: outcomeLabels
  })), React.createElement(CouponSingelPixChoices, {
    boardIndex: selectedBoard,
    couponId: couponId
  }));
};
setGlobal('svs.components.tipsen.coupon.CouponSingelRowsMobile', CouponSingelRowsMobile);

 })(window);