(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon/assets/javascripts/coupon-row-description.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon/assets/javascripts/coupon-row-description.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  selectParticipant
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  DescriptionSize
} = svs.components.lbUi.participants.constants;
const {
  ParticipantsNames
} = svs.components.lbUi.participants;
const eventTypeDictionary = {
  [EventTypeId.HEAD_TO_HEAD]: 'Vem gör flest mål?'
};
const CouponRowDescription = _ref => {
  let {
    eventTypeId,
    participants,
    eventNumber,
    nameSize = DescriptionSize.Medium
  } = _ref;
  const eventDescription = eventTypeDictionary[eventTypeId];
  const homeParticipant = useSelector(state => selectParticipant(state, participants === null || participants === void 0 ? void 0 : participants[0]));
  const awayParticipant = useSelector(state => selectParticipant(state, participants === null || participants === void 0 ? void 0 : participants[1]));
  return React.createElement("div", {
    className: "coupon-row-description"
  }, React.createElement("div", {
    className: "coupon-row-description-primary"
  }, eventNumber && React.createElement("span", {
    className: "coupon-row-event-number"
  }, "".concat(eventNumber, ".")), React.createElement(ParticipantsNames, {
    awayParticipant: awayParticipant,
    homeParticipant: homeParticipant,
    nameSize: nameSize
  })), eventDescription && React.createElement("div", {
    className: "coupon-row-description-secondary f-400"
  }, eventDescription));
};
setGlobal('svs.components.tipsen.coupon.CouponRowDescription', CouponRowDescription);

 })(window);