(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon/assets/javascripts/fetch-draw-analyses-by-hook.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon/assets/javascripts/fetch-draw-analyses-by-hook.js');
"use strict";


const {
  useFetchAnalysesByDraw
} = svs.components.sportinfo.gameAnalysesRedux.hooks;

const FetchDrawAnalysesByHook = _ref => {
  let {
    productId,
    drawNumber
  } = _ref;
  useFetchAnalysesByDraw(drawNumber, productId);
  return null;
};
FetchDrawAnalysesByHook.propTypes = {
  drawNumber: PropTypes.number,
  productId: PropTypes.number
};
setGlobal('svs.components.tipsen.coupon.FetchDrawAnalysesByHook', FetchDrawAnalysesByHook);

 })(window);