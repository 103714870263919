(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon/assets/javascripts/reduce-free-bets.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon/assets/javascripts/reduce-free-bets.js');
"use strict";

var _parseInt, _styles, _parseInt2, _styles2, _parseInt3, _styles3;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  setSignDistribution,
  toggleAlternative
} = svs.components.tipsen.engine.actions;
const {
  selectEventSelections,
  selectSystemType,
  selectSignDistribution
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  useEffect,
  useCallback,
  useRef,
  useState
} = React;
const {
  ReactIcon,
  ReactButtonBet
} = svs.ui;
const tipsenEl = document.querySelector('#tipsen');
let styles;
if (tipsenEl) {
  styles = window.getComputedStyle(tipsenEl);
}
const BUTTON_BET_MARGIN_SELECTED = (_parseInt = parseInt((_styles = styles) === null || _styles === void 0 ? void 0 : _styles.getPropertyValue('--BUTTON_BET_MARGIN_SELECTED'), 10)) !== null && _parseInt !== void 0 ? _parseInt : 0;
const HALF_BUTTON_BET_MARGIN_SELECTED = BUTTON_BET_MARGIN_SELECTED / 2;
const CONTROLLER_WIDTH = (_parseInt2 = parseInt((_styles2 = styles) === null || _styles2 === void 0 ? void 0 : _styles2.getPropertyValue('--CONTROLLER_WIDTH'), 10)) !== null && _parseInt2 !== void 0 ? _parseInt2 : 0;
const HALF_CONTROLLER_WIDTH = CONTROLLER_WIDTH / 2;
const MIN_BUTTON_SIZE = (_parseInt3 = parseInt((_styles3 = styles) === null || _styles3 === void 0 ? void 0 : _styles3.getPropertyValue('--MIN_BUTTON_SIZE'), 10)) !== null && _parseInt3 !== void 0 ? _parseInt3 : 0;
const MIN_PERCENTAGE = 10;
const clampPercentages = (percent, maxPercent) => Math.min(Math.max(percent, MIN_PERCENTAGE), maxPercent);
const SelectedControl = {
  None: 0,
  Left: 1,
  Right: 2
};
const Index = {
  Home: 0,
  Draw: 1,
  Away: 2
};
const preventDefault = e => {
  e.preventDefault();
};
const ReduceButtonBet = _ref => {
  let {
    branding,
    className,
    dataTestId,
    isDisabled,
    label,
    onClick,
    refFunc,
    selected,
    index
  } = _ref;
  const buttonOnClick = useCallback(() => {
    onClick(index);
  }, [onClick, index]);
  return React.createElement(ReactButtonBet, {
    branding: branding,
    className: className,
    "data-testid": dataTestId,
    disabled: isDisabled,
    label: label,
    onClick: buttonOnClick,
    ref: refFunc,
    selected: selected,
    type: "button"
  });
};
const ReduceFreeBets = _ref2 => {
  let {
    couponId,
    board,
    event,
    outcomeLabels,
    isDisabled
  } = _ref2;
  const dispatch = useDispatch();
  const [productBranding] = useBranding();
  const systemType = useSelector(state => selectSystemType(state, couponId));
  const selections = useSelector(state => selectEventSelections(state, couponId, board, event));
  const distribution = useSelector(state => selectSignDistribution(state, couponId, board, event));
  const [selectedControl, setSelectedControl] = useState(SelectedControl.None);
  const [originalPosition, setOriginalPosition] = useState();
  const isControlLeftVisible = !isDisabled && selections[Index.Home] && (selections[Index.Draw] || selections[Index.Away]);
  const isControlRightVisible = !isDisabled && selections[Index.Away] && (selections[Index.Draw] || selections[Index.Home]);
  const reduceContainer = useRef();
  const reduceContainerWidth = useRef(0);
  const controllers = useRef({
    leftController: undefined,
    rightController: undefined
  });
  const betButtons = useRef([undefined, undefined, undefined]);
  const distributionToFraction = useCallback(indexDistribution => indexDistribution / distribution.reduce((a, b) => a + b, 0), [distribution]);
  const distributionToPercentage = useCallback(indexDistribution => distributionToFraction(indexDistribution) * 100, [distributionToFraction]);
  const numSelectedButtons = selections.filter(selected => selected).length;
  const allButtonsSelected = numSelectedButtons === selections.length;
  const homePercentage = distributionToPercentage(distribution[Index.Home]);
  const drawPercentage = distributionToPercentage(distribution[Index.Draw]);
  const awayPercentage = distributionToPercentage(distribution[Index.Away]);
  const newHomePercentage = useRef(homePercentage);
  const newDrawPercentage = useRef(drawPercentage);
  const newAwayPercentage = useRef(awayPercentage);
  const roundOfAThird = useCallback(() => {
    if (distribution[Index.Home] === distribution[Index.Draw] && distribution[Index.Draw] === distribution[Index.Away] && newHomePercentage.current === newAwayPercentage.current) {
      newHomePercentage.current = 34; 
      newDrawPercentage.current = 33;
      newAwayPercentage.current = 33;
    }
  }, [distribution]);
  useEffect(() => {
    newHomePercentage.current = distributionToPercentage(distribution[Index.Home]);
    newDrawPercentage.current = distributionToPercentage(distribution[Index.Draw]);
    newAwayPercentage.current = distributionToPercentage(distribution[Index.Away]);
    roundOfAThird();
  }, [distribution, roundOfAThird, distributionToPercentage]);
  const calculateWidthWithoutMargin = useCallback(width => {
    var _parseInt4, _styles4;
    const BUTTON_BET_MARGIN = (_parseInt4 = parseInt((_styles4 = styles) === null || _styles4 === void 0 ? void 0 : _styles4.getPropertyValue('--BUTTON_BET_MARGIN'), 10)) !== null && _parseInt4 !== void 0 ? _parseInt4 : 0;
    if (allButtonsSelected || selections[Index.Home] && selections[Index.Away]) {
      return width - 2 * BUTTON_BET_MARGIN_SELECTED;
    } else if (selections[Index.Home] && selections[Index.Draw] || selections[Index.Away] && selections[Index.Draw]) {
      return width - (BUTTON_BET_MARGIN_SELECTED + BUTTON_BET_MARGIN);
    }
    return width - 2 * BUTTON_BET_MARGIN;
  }, [allButtonsSelected, selections]);
  const getBetButtonWidth = useCallback(() => {
    const buttonSize = Math.round(calculateWidthWithoutMargin(reduceContainerWidth.current) * (MIN_PERCENTAGE / 100));
    return buttonSize < MIN_BUTTON_SIZE ? MIN_BUTTON_SIZE : buttonSize;
  }, [calculateWidthWithoutMargin]);
  const getAvailableReduceContainerWidth = useCallback(() => {
    let pixelsToSubtract = 0;
    selections.forEach(selected => {
      if (!selected) {
        pixelsToSubtract += getBetButtonWidth();
      }
    });
    const reducerContainerWidth = calculateWidthWithoutMargin(reduceContainerWidth.current);
    return reducerContainerWidth - pixelsToSubtract;
  }, [calculateWidthWithoutMargin, getBetButtonWidth, selections]);
  const getTotalAvailableWidthFraction = useCallback(() => getAvailableReduceContainerWidth() / 100, [getAvailableReduceContainerWidth]);
  const getButtonSizes = useCallback(() => {
    let availableWidth = reduceContainerWidth.current;
    if (numSelectedButtons === 0) {
      const width = availableWidth / selections.length;
      return {
        homeWidth: width,
        drawWidth: width,
        awayWidth: width
      };
    }
    const percentages = [{
      index: Index.Home,
      percent: newHomePercentage.current
    }, {
      index: Index.Draw,
      percent: newDrawPercentage.current
    }, {
      index: Index.Away,
      percent: newAwayPercentage.current
    }];
    const totalAvailableWidthFraction = getTotalAvailableWidthFraction();
    const newWidths = [];
    const weightedPercentages = percentages.sort((a, b) => a.percent - b.percent);
    weightedPercentages.forEach(el => {
      let width;
      if (selections[el.index]) {
        const percentWidth = el.percent * totalAvailableWidthFraction;
        width = percentWidth < MIN_BUTTON_SIZE ? MIN_BUTTON_SIZE : percentWidth;
      } else {
        width = getBetButtonWidth();
      }
      if (availableWidth < width) {
        width = availableWidth;
      }
      availableWidth -= width;
      newWidths[el.index] = width;
    });
    return {
      homeWidth: newWidths[Index.Home],
      drawWidth: newWidths[Index.Draw],
      awayWidth: newWidths[Index.Away]
    };
  }, [getBetButtonWidth, getTotalAvailableWidthFraction, numSelectedButtons, selections]);
  const getControllerPositions = useCallback((homeWidth, awayWidth) => {
    const leftControlPosition = homeWidth + HALF_BUTTON_BET_MARGIN_SELECTED;
    const rightControlPosition = reduceContainerWidth.current - (awayWidth + HALF_BUTTON_BET_MARGIN_SELECTED);
    return {
      leftControlPosition: leftControlPosition - HALF_CONTROLLER_WIDTH,
      rightControlPosition: rightControlPosition - HALF_CONTROLLER_WIDTH
    };
  }, []);
  const updateInterface = useCallback(() => {
    const {
      homeWidth,
      drawWidth,
      awayWidth
    } = getButtonSizes();
    const {
      leftControlPosition,
      rightControlPosition
    } = getControllerPositions(homeWidth, awayWidth);
    const homeBetButton = betButtons.current[Index.Home];
    const drawBetButton = betButtons.current[Index.Draw];
    const awayBetButton = betButtons.current[Index.Away];
    const windowAnimation = (homeWidth, drawWidth, awayWidth, leftControlPosition, rightControlPosition) => {
      betButtons.current[Index.Home].style.width = "".concat(Math.round(homeWidth), "px");
      betButtons.current[Index.Draw].style.width = "".concat(Math.round(drawWidth), "px");
      betButtons.current[Index.Away].style.width = "".concat(Math.round(awayWidth), "px");
      if (controllers.current.leftController) {
        controllers.current.leftController.style.transform = "translate3d(".concat(Math.round(leftControlPosition), "px, 0, 0)");
      }
      if (controllers.current.rightController) {
        controllers.current.rightController.style.transform = "translate3d(".concat(Math.round(rightControlPosition), "px, 0, 0)");
      }
    };
    const requestId = window.requestAnimationFrame(() => {
      windowAnimation(homeWidth, drawWidth, awayWidth, leftControlPosition, rightControlPosition);
    });
    if (!homeBetButton || !drawBetButton || !awayBetButton) {
      window.cancelAnimationFrame(requestId);
    }
  }, [getButtonSizes, getControllerPositions]);
  const updateLabels = useCallback(() => {
    const roundedHomePercent = Math.round(newHomePercentage.current);
    const roundedAwayPercent = Math.round(newAwayPercentage.current);
    const roundedDrawPercent = 100 - (roundedHomePercent + roundedAwayPercent);
    const labels = [];
    labels[Index.Home] = selections[Index.Home] ? "".concat(roundedHomePercent, "%") : outcomeLabels[Index.Home].text;
    labels[Index.Draw] = selections[Index.Draw] ? "".concat(roundedDrawPercent, "%") : outcomeLabels[Index.Draw].text;
    labels[Index.Away] = selections[Index.Away] ? "".concat(roundedAwayPercent, "%") : outcomeLabels[Index.Away].text;
    labels.forEach((label, index) => {
      const labelEl = betButtons.current[index].querySelector('.btn-bet-label');
      if (labelEl) {
        labelEl.textContent = label;
      } else {
        betButtons.current[index].textContent = label;
      }
    });
  }, [outcomeLabels, selections]);
  const maxUncontrolledPercentage = 100 - (numSelectedButtons - 1) * MIN_PERCENTAGE;
  const calculateNewPercentages = useCallback((moveDiff, controlledIndex) => {
    let maxControlledPercentage = 100;
    if (allButtonsSelected) {
      const uncontrolledPercentaged = controlledIndex === Index.Home ? awayPercentage : homePercentage;
      maxControlledPercentage -= uncontrolledPercentaged + MIN_PERCENTAGE; 
    } else {
      maxControlledPercentage -= (numSelectedButtons - 1) * MIN_PERCENTAGE;
    }
    const totalAvailableWidthFraction = getTotalAvailableWidthFraction();
    if (controlledIndex === Index.Home) {
      newHomePercentage.current = selections[Index.Home] ? clampPercentages(homePercentage + moveDiff / totalAvailableWidthFraction, maxControlledPercentage) : 0;
      if (allButtonsSelected) {
        newAwayPercentage.current = awayPercentage;
      } else if (selections[Index.Away]) {
        newAwayPercentage.current = clampPercentages(awayPercentage - moveDiff / totalAvailableWidthFraction, maxUncontrolledPercentage);
      } else {
        newAwayPercentage.current = 0;
      }
    } else if (controlledIndex === Index.Away) {
      newAwayPercentage.current = selections[Index.Away] ? clampPercentages(awayPercentage - moveDiff / totalAvailableWidthFraction, maxControlledPercentage) : 0;
      if (allButtonsSelected) {
        newHomePercentage.current = homePercentage;
      } else if (selections[Index.Home]) {
        newHomePercentage.current = clampPercentages(homePercentage + moveDiff / totalAvailableWidthFraction, maxUncontrolledPercentage);
      } else {
        newHomePercentage.current = 0;
      }
    }
    newDrawPercentage.current = 100 - (newHomePercentage.current + newAwayPercentage.current);
    roundOfAThird();
  }, [allButtonsSelected, awayPercentage, homePercentage, numSelectedButtons, roundOfAThird, selections, getTotalAvailableWidthFraction, maxUncontrolledPercentage]);
  const handleBetButtonClick = useCallback(index => {
    dispatch(toggleAlternative({
      boardIndex: board,
      couponId,
      event,
      outcome: index,
      systemType
    }));
  }, [board, couponId, dispatch, event, systemType]);
  useEffect(() => {
    updateLabels();
  }, [updateLabels]);
  useEffect(() => {
    const el = reduceContainer.current;
    let resizeObserver;
    if (el) {
      resizeObserver = new ResizeObserver(_ref3 => {
        let [entry] = _ref3;
        reduceContainerWidth.current = entry.contentRect.width;
        updateInterface();
      });
      resizeObserver.observe(el);
    }
    updateInterface();
    return () => {
      if (resizeObserver) {
        resizeObserver.unobserve(el);
      }
    };
  }, [updateInterface]);
  useEffect(() => {
    const onControlMoveEvent = position => {
      const moveDiff = position - originalPosition;
      const controlledIndex = selectedControl === SelectedControl.Left ? Index.Home : Index.Away;
      calculateNewPercentages(moveDiff, controlledIndex);
      updateInterface();
      updateLabels();
    };
    const onMouseMoveEvent = e => {
      onControlMoveEvent(e.pageX);
    };
    const onTouchMoveEvent = e => {
      onControlMoveEvent(e.touches[0].clientX);
    };
    const removeAllListeners = () => {
      window.removeEventListener('mousemove', onMouseMoveEvent);
      window.removeEventListener('touchmove', onTouchMoveEvent);
    };
    if (selectedControl === SelectedControl.None) {
      removeAllListeners();
    } else {
      window.addEventListener('mousemove', onMouseMoveEvent);
      window.addEventListener('touchmove', onTouchMoveEvent);
    }
    return () => {
      removeAllListeners();
    };
  }, [selectedControl, calculateNewPercentages, originalPosition, updateInterface, updateLabels]);
  useEffect(() => {
    const onControlUpEvent = () => {
      dispatch(setSignDistribution({
        couponId,
        board,
        event,
        distributions: [newHomePercentage.current, newDrawPercentage.current, newAwayPercentage.current]
      }));
      setSelectedControl(SelectedControl.None);
      setOriginalPosition(undefined);
    };
    const onMouseUpEvent = () => {
      onControlUpEvent();
    };
    const onTouchEndEvent = () => {
      onControlUpEvent();
    };
    const removeAllListeners = () => {
      window.removeEventListener('mouseup', onMouseUpEvent);
      window.removeEventListener('touchend', onTouchEndEvent);
    };
    if (selectedControl === SelectedControl.None) {
      removeAllListeners();
    } else {
      window.addEventListener('mouseup', onMouseUpEvent);
      window.addEventListener('touchend', onTouchEndEvent);
    }
    return () => {
      removeAllListeners();
    };
  }, [board, couponId, dispatch, event, selectedControl]);
  const onControlDownEvent = (controller, position) => {
    setOriginalPosition(position);
    setSelectedControl(controller);
  };
  const onMouseDownEvent = (e, controller) => {
    e.preventDefault();
    onControlDownEvent(controller, e.pageX);
  };
  const onTouchStartEvent = (e, controller) => {
    onControlDownEvent(controller, e.touches[0].clientX); 
  };
  return React.createElement("div", {
    className: "reduce-free-container",
    onClick: preventDefault,
    onMouseDown: preventDefault,
    onMouseUp: preventDefault,
    ref: reduceContainer
  }, React.createElement("div", {
    className: "reduce-free-outcome-container"
  }, isControlRightVisible && React.createElement("button", {
    className: "reduce-free-outcome-slider-button",
    onContextMenu: preventDefault 
    ,
    onMouseDown: e => {
      onMouseDownEvent(e, SelectedControl.Right);
    },
    onTouchStart: e => {
      onTouchStartEvent(e, SelectedControl.Right);
    },
    ref: node => {
      controllers.current.rightController = node;
    },
    type: "button"
  }, React.createElement("div", {
    className: "reduce-free-outcome-slider-content"
  }, React.createElement(ReactIcon, {
    color: "fc-red",
    icon: "caret-left",
    size: "100"
  }), React.createElement(ReactIcon, {
    color: "fc-red",
    icon: "caret-right",
    size: "100"
  }))), isControlLeftVisible && React.createElement("button", {
    className: "reduce-free-outcome-slider-button",
    onContextMenu: preventDefault 
    ,
    onMouseDown: e => {
      onMouseDownEvent(e, SelectedControl.Left);
    },
    onTouchStart: e => {
      onTouchStartEvent(e, SelectedControl.Left);
    },
    ref: node => {
      controllers.current.leftController = node;
    },
    type: "button"
  }, React.createElement("div", {
    className: "reduce-free-outcome-slider-content"
  }, React.createElement(ReactIcon, {
    color: "fc-red",
    icon: "caret-left",
    size: "100"
  }), React.createElement(ReactIcon, {
    color: "fc-red",
    icon: "caret-right",
    size: "100"
  }))), React.createElement("div", {
    className: "reduce-free-outcomes"
  }, selections.map((selection, index) => {
    const key = "".concat(board, "_").concat(event, "_").concat(index);
    const buttonClassNames = ['coupon-button-bet', 'reduce-free-btn-bet'];
    if (index === Index.Home && isControlLeftVisible || index === Index.Away && isControlRightVisible) {
      buttonClassNames.push('coupon-button-bet-selected-margin');
    }
    return React.createElement(ReduceButtonBet, {
      branding: productBranding,
      className: buttonClassNames.join(' '),
      dataTestId: key,
      index: index,
      isDisabled: isDisabled,
      key: key,
      label: outcomeLabels[index].text,
      onClick: handleBetButtonClick,
      refFunc: node => {
        betButtons.current[index] = node;
      },
      selected: selection,
      type: "button"
    });
  }))));
};
setGlobal('svs.components.tipsen.coupon.ReduceFreeBets', ReduceFreeBets);

 })(window);