(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon/assets/javascripts/coupon-single-rows.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon/assets/javascripts/coupon-single-rows.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const {
  useContext,
  useState
} = React;
const {
  SwipeArrows
} = svs.sport.swipeArrows;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  CouponSingleRowBoard,
  CouponSingleRow,
  CouponSingelRowsMobile
} = svs.components.tipsen.coupon;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_AM
} = svs.components.lbUtils.useMediaQuery;
const {
  FrameBlur
} = svs.components.tipsen.frameBlur;
const {
  framePosition
} = svs.components.tipsen.frameBlur.constants;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const CouponSingleRows = _ref => {
  let {
    boardSize,
    couponId,
    events,
    outcomeLabels,
    selectedBoard
  } = _ref;
  const [productBranding] = useBranding();
  const {
    productId,
    drawNumber
  } = useContext(DrawContext);
  const mappedBoards = [];
  for (let i = 0; i < boardSize; i++) {
    events.forEach(() => {
      if (!mappedBoards[i]) {
        mappedBoards.push([]);
      }
      mappedBoards[i].push(outcomeLabels);
    });
  }
  const [scrollElement, setScrollElement] = useState(null);
  const getLeftScroll = () => {
    const contentHolder = document.querySelector('.coupon-single-rows-board-columns');
    return contentHolder.scrollLeft - contentHolder.clientWidth;
  };
  const getRightScroll = () => {
    const contentHolder = document.querySelector('.coupon-single-rows-board-columns');
    const clientWidth = contentHolder.clientWidth;

    const colWidth = 114;

    const numberOfCols = Math.ceil(clientWidth / colWidth);
    return contentHolder.scrollLeft + numberOfCols * colWidth;
  };
  const leftArrowOffset = document.querySelector('.coupon-single-rows-event-column') ? document.querySelector('.coupon-single-rows-event-column').clientWidth - 24 : 190;
  const arrowVerticalPadding = 366;
  const isSmall = useMediaQuery(breakpoints.down(BREAKPOINT_AM));
  const showArrows = !isSmall || isSelfService;
  if (isSmall) {
    return React.createElement(CouponSingelRowsMobile, {
      boardSize: boardSize,
      couponId: couponId,
      events: events,
      outcomeLabels: outcomeLabels,
      selectedBoard: selectedBoard
    });
  }
  return React.createElement("div", {
    className: "coupon-single-rows"
  }, React.createElement("div", {
    className: "coupon-single-rows-event-column"
  }, events.map(drawEvent => React.createElement(CouponSingleRow, {
    branding: productBranding,
    drawNumber: drawNumber,
    event: drawEvent,
    isSingleRowForSmallDevice: isSmall,
    key: drawEvent.eventNumber,
    productId: productId
  }))), showArrows && React.createElement(React.Fragment, null, React.createElement(SwipeArrows, {
    arrowVerticalPadding: arrowVerticalPadding,
    getLeftScrollWidth: getLeftScroll,
    getRightScrollWidth: getRightScroll,
    leftArrowOffset: leftArrowOffset,
    scrollContentEl: scrollElement,
    scrollParentSelector: ".coupon-single-rows-board-columns"
  }), React.createElement(FrameBlur, {
    className: "single-rows-frame-blur",
    position: framePosition.START,
    scrollElement: scrollElement
  }), React.createElement(FrameBlur, {
    className: "single-rows-frame-blur",
    position: framePosition.END,
    scrollElement: scrollElement
  })), React.createElement("div", {
    className: "coupon-single-rows-board-columns qa-coupon-singlerow-columns",
    ref: node => {
      if (node) {
        setScrollElement(node);
      }
    }
  }, mappedBoards.map((board, index) => {
    const currentKey = "".concat(couponId, "_").concat(index);
    return React.createElement(CouponSingleRowBoard, {
      board: board,
      boardIndex: index,
      couponId: couponId,
      key: currentKey,
      outcomeLabels: outcomeLabels
    });
  })));
};
setGlobal('svs.components.tipsen.coupon.CouponSingleRows', CouponSingleRows);

 })(window);