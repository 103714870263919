(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon/assets/javascripts/coupon-single-row-board.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon/assets/javascripts/coupon-single-row-board.js');
"use strict";

const {
  CouponBetButtons
} = svs.components.tipsen.couponBetButtons;
const {
  CouponSingelPixChoices
} = svs.components.tipsen.coupon;
const {
  useIsCurrentDrawPlayable
} = svs.components.tipsen.hooks;
const CouponSingleRowBoard = _ref => {
  let {
    couponId,
    board,
    boardIndex,
    outcomeLabels,
    isSingleRowForSmallDevice = false
  } = _ref;
  const isDrawPlayable = useIsCurrentDrawPlayable();
  return React.createElement("div", {
    className: "coupon-single-rows-board",
    key: "coupon-row-".concat(boardIndex + 1)
  }, !isSingleRowForSmallDevice && React.createElement("div", {
    className: "coupon-single-rows-board-header f-200"
  }, boardIndex + 1), React.createElement("div", {
    className: "coupon-single-rows-board-outcome"
  }, board.map((outcomes, index) => React.createElement("div", {
    className: "coupon-single-rows-bet-buttons"
    ,
    key: "".concat(boardIndex, "_").concat(index)
  }, React.createElement(CouponBetButtons, {
    board: boardIndex,
    couponId: couponId,
    event: index,
    hasDisabledBets: !isDrawPlayable,
    outcomeLabels: outcomeLabels,
    outcomes: outcomes.map(outcome => outcome.text)
  })))), !isSingleRowForSmallDevice && React.createElement(CouponSingelPixChoices, {
    boardIndex: boardIndex,
    couponId: couponId
  }));
};
setGlobal('svs.components.tipsen.coupon.CouponSingleRowBoard', CouponSingleRowBoard);

 })(window);