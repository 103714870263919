(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon/assets/javascripts/hooks/use-observe-settings.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon/assets/javascripts/hooks/use-observe-settings.js');
"use strict";


const {
  useEffect,
  useContext
} = React;
const {
  selectShowCompressedCoupon,
  selectOutcomeInfoChoise
} = svs.components.tipsen.selectors;
const {
  setOutcomeInfoChoises
} = svs.components.tipsen.store.actions.couponSettingsActions;
const {
  selectModuleName
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  TipsinfoTypes
} = svs.components.tipsen.engine.constants;
const {
  RenderContext
} = svs.component.tipsen.renderContext;
const useObserveSettings = () => {
  const {
    pageType
  } = useContext(RenderContext);
  const dispatch = useDispatch();
  const moduleName = useSelector(selectModuleName);
  const outcomeInfoChoises = useSelector(state => selectOutcomeInfoChoise(state, pageType));
  const isExtraSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const showCompressedCoupon = useSelector(selectShowCompressedCoupon);
  useEffect(() => {
    if (isExtraSmall && showCompressedCoupon && outcomeInfoChoises.display === TipsinfoTypes.SvenskaFolket) {
      dispatch(setOutcomeInfoChoises({
        moduleName,
        changes: [{
          field: 'display',
          state: TipsinfoTypes.OutcomeLabel
        }]
      }));
    }
  }, [isExtraSmall, moduleName, showCompressedCoupon, outcomeInfoChoises, dispatch]);
  return null;
};
setGlobal('svs.components.tipsen.coupon.hooks.useObserveSettings', useObserveSettings);

 })(window);