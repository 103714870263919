(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon/assets/javascripts/coupon-single-row.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon/assets/javascripts/coupon-single-row.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  ReactBadge: Badge
} = svs.ui;
const {
  createBetEventKey
} = svs.components.sportinfo.common.helpers;
const {
  selectBetEvent
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  DescriptionSize
} = svs.components.lbUi.participants.constants;
const {
  CouponRowDescription
} = svs.components.tipsen.coupon;

const CouponSingleRow = _ref => {
  let {
    productId,
    drawNumber,
    event,
    branding,
    isSingleRowForSmallDevice
  } = _ref;
  const betEvent = useSelector(state => selectBetEvent(state, createBetEventKey(productId, drawNumber, event.eventNumber)));
  const nameSize = isSingleRowForSmallDevice ? DescriptionSize.Short : DescriptionSize.Medium;
  return React.createElement("div", {
    className: "coupon-single-row-event",
    key: event.eventNumber
  }, React.createElement(Badge, {
    branding: branding,
    className: isSingleRowForSmallDevice ? 'coupon-singels-row-number' : 'coupon-row-number'
  }, event.eventNumber), React.createElement(CouponRowDescription, {
    eventTypeId: event.eventTypeId,
    nameSize: nameSize,
    participants: betEvent === null || betEvent === void 0 ? void 0 : betEvent.participants,
    shouldUseShortNames: isSingleRowForSmallDevice
  }));
};
setGlobal('svs.components.tipsen.coupon.CouponSingleRow', CouponSingleRow);

 })(window);