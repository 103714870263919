(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon/assets/javascripts/coupon-single-row-pix-choices.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon/assets/javascripts/coupon-single-row-pix-choices.js');
"use strict";

const {
  useContext
} = React;
const {
  ReactIcon
} = svs.ui;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  XPERT_WAGER_SROW
} = svs.components.tipsen.engine.constants.xpertWagerSystemTypes;
const {
  selectHasBoardUserSigns,
  selectBoardUserSigns,
  selectHasBoardSignsNotByUser
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  useClearCouponBoard,
  useIsCouponBoardEmpty
} = svs.components.tipsen.hooks;
const {
  useIsCurrentDrawPlayable
} = svs.components.tipsen.hooks;
const {
  useGenerateSportPixCouponBoardAction
} = svs.components.tipsen.engine.utils.helpers;
const CouponSingelPixChoices = _ref => {
  let {
    couponId,
    boardIndex
  } = _ref;
  const {
    productId,
    drawNumber
  } = useContext(DrawContext);
  const generateSportPixCouponBoard = useGenerateSportPixCouponBoardAction();
  const isBoardEmpty = useIsCouponBoardEmpty(couponId, boardIndex);
  const hasUserSigns = useSelector(state => selectHasBoardUserSigns(state, couponId, boardIndex));
  const userSigns = useSelector(state => selectBoardUserSigns(state, couponId, boardIndex));
  const hasSignsNotByUser = useSelector(state => selectHasBoardSignsNotByUser(state, couponId, boardIndex));
  const dispatch = useDispatch();
  const onClearCouponBoard = useClearCouponBoard(couponId);
  const isDrawPlayable = useIsCurrentDrawPlayable();
  const onClearRow = () => {
    onClearCouponBoard(boardIndex);
  };
  const onGeneratePix = () => {
    const _generateWithUserSigns = () => {
      dispatch(generateSportPixCouponBoard({
        productId,
        boardIndex,
        drawNumber,
        couponId,
        system: 1,
        baseRow: userSigns,
        keepUserSigns: true,
        type: XPERT_WAGER_SROW
      }));
    };
    const _generateWithoutUserSigns = () => {
      dispatch(generateSportPixCouponBoard({
        productId,
        boardIndex,
        drawNumber,
        couponId,
        system: 1,
        baseRow: [],
        keepUserSigns: false,
        type: XPERT_WAGER_SROW
      }));
    };
    if (hasUserSigns) {
      const dialog = new svs.components.dialog.Confirm({
        branding: svs.components.dialog.branding.SPORT,
        icon: 'pix',
        area: svs.components.dialog.area.POPUP,
        autoClose: 0,
        type: svs.components.dialog.type.DEFAULT,
        title: 'Vill du behålla dina tecken?',
        message: [{
          type: svs.components.dialog.message.TEXT,
          text: 'Vill du utgå ifrån dina egna val eller skapa en ny rad från grunden?'
        }],
        actions: [{
          title: 'Från grunden',
          showLoader: true,
          callback() {
            _generateWithoutUserSigns();
            dialog.close();
          }
        }, {
          title: 'Egna val',
          showLoader: true,
          callback() {
            _generateWithUserSigns();
            dialog.close();
          }
        }]
      });
      svs.components.dialog.api.add(dialog);
    } else {
      _generateWithoutUserSigns();
    }
  };
  return React.createElement("div", {
    className: "coupon-single-rows-board-choices"
  }, React.createElement("button", {
    className: "coupon-choices-button clear-board-button",
    disabled: !isDrawPlayable,
    onClick: onGeneratePix,
    title: "Fyll i rad med PIX!",
    type: "button"
  }, React.createElement(ReactIcon, {
    icon: hasSignsNotByUser ? 'refresh-pix' : 'pix',
    size: "100"
  })), React.createElement("button", {
    className: "coupon-choices-button pix-board-button",
    disabled: isBoardEmpty,
    onClick: onClearRow,
    title: "Rensa rad",
    type: "button"
  }, React.createElement(ReactIcon, {
    icon: "dustbin",
    size: "100"
  })));
};
setGlobal('svs.components.tipsen.coupon.CouponSingelPixChoices', CouponSingelPixChoices);

 })(window);